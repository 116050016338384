// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import menuItem from 'menu-items';
import AuthUser from 'views/pages/authentication/authentication3/AuthUser';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
    const { user } = AuthUser();
    const navItems = menuItem.items.map((item) => {
        const profil = item.group?.find((profil) => profil === user?.profil);
        if (profil) {
            switch (item.type) {
                case 'group':
                    return <NavGroup key={item.id} item={item} />;
                default:
                    return (
                        <Typography key={item.id} variant="h6" color="error" align="center">
                            Menu Items Error
                        </Typography>
                    );
            }
        }
    });

    return <>{navItems}</>;
};

export default MenuList;
